import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { FighterDirection } from './Entities/Fighters/Game.Fighter';
const heldKeys = new Set()


export function regiterKeyboardEvents() {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
}

export const Control = {
    LEFT: 'left',
    RIGHT: 'right',
    UP: 'up',
    DOWN: 'down',
    AttackA: 'attackA',
    AttackB: 'attackB',
    AttackC: 'attackC'
}

export const controls = [
    {
        keyboard: {
            [Control.LEFT]: 'KeyA',
            [Control.RIGHT]: 'KeyD',
            [Control.UP]: 'KeyW',
            [Control.DOWN]: 'Keys',
            [Control.AttackA]: 'Digit1',
            [Control.AttackB]: 'Digit2',
            [Control.AttackC]: 'Digit3',
        }

    },
    {
        keyboard: {
            [Control.LEFT]: 'ArrowLeft',
            [Control.RIGHT]: 'ArrowRight',
            [Control.UP]: 'ArrowUp',
            [Control.DOWN]: 'ArrowDown',
            [Control.AttackA]: 'Digit0',
            [Control.AttackB]: 'Digit9',
            [Control.AttackC]: 'Digit8',
        }
    },
]

const mappedKeys = controls.map(({ keyboard }) => Object.values(keyboard)).flat()


export const isKeyDown = (code: string) => heldKeys.has(code)
export const isKeyUp = (code: string) => !heldKeys.has(code)

export const isLeft = (id: number) => isKeyDown(controls[id].keyboard[Control.LEFT])
export const isRight = (id: number) => isKeyDown(controls[id].keyboard[Control.RIGHT])
export const isUp = (id: number) => isKeyDown(controls[id].keyboard[Control.UP])
export const isDown = (id: number) => isKeyDown(controls[id].keyboard[Control.DOWN])
export const isAttackA = (id: number) => isKeyDown(controls[id].keyboard[Control.AttackA])
export const isAttackB = (id: number) => isKeyDown(controls[id].keyboard[Control.AttackB])
export const isAttackC = (id: number) => isKeyDown(controls[id].keyboard[Control.AttackC])

export const isForward = (id: number, direction: number) => direction === FighterDirection.RIGHT ? isRight(id) : isLeft(id)
export const isBackward = (id: number, direction: number) => direction === FighterDirection.LEFT ? isRight(id) : isLeft(id)

export const isIdle = (id: number) => !(isLeft(id) || isRight(id) || isUp(id) || isDown(id))

function handleKeyDown(event: KeyboardEvent) {
    console.log(event.code)
    if (!mappedKeys.includes(event.code)) return

    event.preventDefault()
    heldKeys.add(event.code)
}

function handleKeyUp(event: KeyboardEvent) {
    if (!mappedKeys.includes(event.code)) return

    event.preventDefault()
    heldKeys.delete(event.code)
}