import { Fighter } from "./Fighters/Game.Fighter";

export const STAGE_FLOOR: number = 1150

export class Stage {
    private backgroundSprite: HTMLImageElement;
    private uiSprite: HTMLImageElement;
    private fighters: Fighter[]

    constructor(fighters: Fighter[]) {
        this.backgroundSprite = document.querySelector('img[alt="background"') as HTMLImageElement;
        this.uiSprite = document.querySelector('img[alt="ui"') as HTMLImageElement;
        this.fighters = fighters
    }

    drawDebug(context: CanvasRenderingContext2D) { }

    draw(context: CanvasRenderingContext2D | null) {

        let p1hp = this.fighters[0].hp
        let p1e = this.fighters[0].energy

        let p2hp = this.fighters[1].hp
        let p2e = this.fighters[1].energy

        context?.drawImage(this.backgroundSprite, 0, 0);

        context?.drawImage(this.uiSprite, 0, 0);

        // black bg bars
        context!.fillStyle = "black"
        context?.fillRect(280, 20, 640, 50)
        context?.fillRect(280, 100, 520, 30)
        context?.fillRect(1080, 20, 640, 50)
        context?.fillRect(1200, 100, 520, 30)

        //  p1 - p2
        context!.fillStyle = "#58bb4d"
        context?.fillRect(280, 20, (p1hp / 100) * 640, 50)
        context?.fillRect(1720, 20, ((p2hp / 100) * 640) * -1, 50)
        context!.fillStyle = "#ff0033"
        context?.fillRect(280, 100, (p1e / 100) * 520, 30)
        context?.fillRect(1720, 100, ((p2e / 100) * 520) * -1, 30)
    }

    update(frameTime: { previous: number, secondsPassed: number }) {
    }
}
