import { STAGE_FLOOR } from '../Game.stage';
import { Fighter, FighterState, PushBox } from './Game.Fighter';

export class Player1 extends Fighter {
    constructor(direction: number, playerId: number) {
        const sprite = document.querySelector('img[alt="player1"') as HTMLImageElement
        super({
            sprite,
            velocity: 0,
            position: {
                x: 200,
                y: STAGE_FLOOR,
            },
            name: "Player1",
            direction: direction,
            playerId: playerId
        })

        this.frames = new Map([
            ['idle-1', [[0 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-2', [[1 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-3', [[2 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-4', [[3 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-5', [[4 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-6', [[5 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-7', [[6 * 520, 0 * 520, 520, 520], PushBox.IDLE]],
            ['idle-8', [[7 * 520, 0 * 520, 520, 520], PushBox.IDLE]],

            ['jump-1', [[0 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-2', [[1 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-3', [[2 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-4', [[3 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-5', [[4 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-6', [[5 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-7', [[6 * 520, 1 * 520, 520, 520], PushBox.JUMP]],
            ['jump-8', [[7 * 520, 1 * 520, 520, 520], PushBox.JUMP]],

            ['forwards-1', [[1 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-2', [[1 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-3', [[2 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-4', [[3 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-5', [[4 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-6', [[5 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-7', [[6 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['forwards-8', [[7 * 520, 7 * 520, 520, 520], PushBox.IDLE]],

            ['backwards-1', [[1 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-2', [[1 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-3', [[2 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-4', [[3 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-5', [[4 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-6', [[5 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-7', [[6 * 520, 7 * 520, 520, 520], PushBox.IDLE]],
            ['backwards-8', [[7 * 520, 7 * 520, 520, 520], PushBox.IDLE]],

            ['attack-a-1', [[0 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-2', [[1 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-3', [[2 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-4', [[3 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-5', [[4 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-6', [[5 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-7', [[6 * 520, 4 * 520, 520, 520], PushBox.IDLE]],
            ['attack-a-8', [[7 * 520, 4 * 520, 520, 520], PushBox.IDLE]],

            ['attack-b-1', [[0 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-2', [[1 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-3', [[2 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-4', [[3 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-5', [[4 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-6', [[5 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-7', [[6 * 520, 5 * 520, 520, 520], PushBox.IDLE]],
            ['attack-b-8', [[7 * 520, 5 * 520, 520, 520], PushBox.IDLE]],

            ['attack-c-1', [[0 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-2', [[1 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-3', [[2 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-4', [[3 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-5', [[4 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-6', [[5 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-7', [[6 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
            ['attack-c-8', [[7 * 520, 6 * 520, 520, 520], PushBox.IDLE]],
        ])

        this.animations = {
            [FighterState.IDLE]: [
                ['idle-1', 68],
                ['idle-2', 68],
                ['idle-3', 68],
                ['idle-4', 68],
                ['idle-5', 68],
                ['idle-6', 68],
                ['idle-7', 68],
                ['idle-8', 68]
            ],
            [FighterState.JUMP_UP]: [
                ['jump-1', 180],
                ['jump-2', 100],
                ['jump-3', 100],
                ['jump-4', 100],
                ['jump-5', 100],
                ['jump-6', 100],
                ['jump-7', 100],
                ['jump-8', -1],
            ],
            [FighterState.JUMP_START]: [
                ['jump-7', 100],
                ['jump-8', -2],
            ],
            [FighterState.JUMP_LAND]: [
                ['jump-1', 180],
                ['jump-8', -2],
            ],
            [FighterState.JUMP_FORWARD]: [
                ['jump-1', 180],
                ['jump-2', 100],
                ['jump-3', 100],
                ['jump-4', 100],
                ['jump-5', 100],
                ['jump-6', 100],
                ['jump-7', 100],
                ['jump-8', -1],
            ],
            [FighterState.JUMP_BACKWARD]: [
                ['jump-1', 180],
                ['jump-2', 100],
                ['jump-3', 100],
                ['jump-4', 100],
                ['jump-5', 100],
                ['jump-6', 100],
                ['jump-7', 100],
                ['jump-8', -1],
            ],
            [FighterState.WALK_FORWARD]: [
                ['forwards-1', 65],
                ['forwards-2', 65],
                ['forwards-3', 65],
                ['forwards-4', 65],
                ['forwards-5', 65],
                ['forwards-6', 65],
                ['forwards-7', 65],
                ['forwards-8', 65]
            ],
            [FighterState.WALK_BACKWARDS]: [
                ['backwards-1', 65],
                ['backwards-2', 65],
                ['backwards-3', 65],
                ['backwards-4', 65],
                ['backwards-5', 65],
                ['backwards-6', 65],
                ['backwards-7', 65],
                ['backwards-8', 65],
            ],
            [FighterState.ATTACK_A]: [
                ['attack-a-1', 68],
                ['attack-a-2', 68],
                ['attack-a-3', 68],
                ['attack-a-4', 68],
                ['attack-a-5', 68],
                ['attack-a-6', 68],
                ['attack-a-7', 68],
                ['attack-a-8', -2]
            ],
            [FighterState.ATTACK_B]: [
                ['attack-b-1', 68],
                ['attack-b-2', 68],
                ['attack-b-3', 68],
                ['attack-b-4', 68],
                ['attack-b-5', 68],
                ['attack-b-6', 68],
                ['attack-b-7', 68],
                ['attack-b-8', -2]
            ],
            [FighterState.ATTACK_C]: [
                ['attack-c-1', 68],
                ['attack-c-2', 68],
                ['attack-c-3', 68],
                ['attack-c-4', 68],
                ['attack-c-5', 68],
                ['attack-c-6', 68],
                ['attack-c-7', 68],
                ['attack-c-8', -2]
            ],
        }

        this.initialVelocity = {
            x: {
                [FighterState.IDLE]: 0,
                [FighterState.WALK_FORWARD]: 500,
                [FighterState.WALK_BACKWARDS]: -300,
                [FighterState.JUMP_FORWARD]: 500,
                [FighterState.JUMP_BACKWARD]: -300,
            },
            jump: -2300
        }
        this.gravity = 5000
    }
}
