import { isKeyDown, isKeyUp, isLeft, isRight, isBackward, isForward, isUp, controls, isIdle, Control, isAttackA, isAttackB, isAttackC } from './../../InputHandler';
import { STAGE_FLOOR } from "../Game.stage";
import { rectsOverlap } from '../../Collisions';
import { textChangeRangeIsUnchanged } from 'typescript';

interface PlayerOptions {
  sprite: HTMLImageElement;
  velocity: number;
  name: string
  position: { x: number; y: number };
  direction: number,
  playerId: number
}

export const FighterDirection = {
  LEFT: -1,
  RIGHT: 1
}

export const FighterState = {
  IDLE: 'idle',
  JUMP_START: 'jumps',
  JUMP_LAND: 'jumpl',
  JUMP_UP: 'jump',
  JUMP_FORWARD: 'jumpf',
  JUMP_BACKWARD: 'jumpb',
  WALK_FORWARD: 'walkForwards',
  WALK_BACKWARDS: 'walkBackwards',
  ATTACK_A: 'attack-a',
  ATTACK_B: 'attack-b',
  ATTACK_C: 'attack-c',
}

export const PushBox = {
  IDLE: [-100, -350, 200, 350],
  JUMP: [-100, -350, 200, 350]
}

export class Fighter {
  name: string
  sprite: HTMLImageElement;
  frames: any
  position: { x: number; y: number };
  direction: number
  velocity: { x: number, y: number } = { x: 0, y: 0 };
  initialVelocity: any = {}
  animationFrame: number = 0
  animationTimer: number = 0
  currentState: string = FighterState.IDLE
  animations: any
  gravity = 0
  playerId: number
  opponent: Fighter
  pushBox = { x: 0, y: 0, width: 0, height: 0 }
  hp: number = 100
  energy: number = 100
  isBlocking: boolean = false
  isAttacking: boolean = false

  states = {
    [FighterState.IDLE]: {
      init: this.handleIdleInit.bind(this),
      update: this.handleIdleState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.JUMP_BACKWARD, FighterState.JUMP_FORWARD,
        FighterState.JUMP_UP, FighterState.WALK_BACKWARDS, FighterState.WALK_FORWARD,
        FighterState.JUMP_LAND, FighterState.ATTACK_A, FighterState.ATTACK_B, FighterState.ATTACK_C,
      ]
    },
    [FighterState.WALK_FORWARD]: {
      init: this.handleMoveInit.bind(this),
      update: this.handleWalkFowardState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_BACKWARDS,
        FighterState.ATTACK_A, FighterState.ATTACK_B, FighterState.ATTACK_C,
      ]
    },
    [FighterState.WALK_BACKWARDS]: {
      init: this.handleMoveInit.bind(this),
      update: this.handleWalkBackwardState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_FORWARD,
        FighterState.ATTACK_A, FighterState.ATTACK_B, FighterState.ATTACK_C,
      ]
    },
    [FighterState.JUMP_UP]: {
      init: this.handleJumpInit.bind(this),
      update: this.handleJumpState.bind(this),
      validFrom: [
        FighterState.JUMP_START
      ]
    },
    [FighterState.JUMP_START]: {
      init: this.handleJumpStartInit.bind(this),
      update: this.handleJumpStartState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_FORWARD, FighterState.WALK_BACKWARDS,
        FighterState.JUMP_LAND
      ]
    },
    [FighterState.JUMP_LAND]: {
      init: this.handleJumpLandInit.bind(this),
      update: this.handleJumpLandState.bind(this),
      validFrom: [
        FighterState.JUMP_UP, FighterState.JUMP_FORWARD, FighterState.JUMP_BACKWARD
      ]
    },
    [FighterState.JUMP_FORWARD]: {
      init: this.handleJumpInit.bind(this),
      update: this.handleJumpState.bind(this),
      validFrom: [
        FighterState.JUMP_START
      ]
    },
    [FighterState.JUMP_BACKWARD]: {
      init: this.handleJumpInit.bind(this),
      update: this.handleJumpState.bind(this),
      validFrom: [
        FighterState.JUMP_START
      ]
    },
    [FighterState.ATTACK_A]: {
      init: this.handleAttackAInit.bind(this),
      update: this.handleAttackAState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_BACKWARDS, FighterState.WALK_FORWARD
      ]
    },
    [FighterState.ATTACK_B]: {
      init: this.handleAttackBInit.bind(this),
      update: this.handleAttackBState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_BACKWARDS, FighterState.WALK_FORWARD
      ]
    },
    [FighterState.ATTACK_C]: {
      init: this.handleAttackCInit.bind(this),
      update: this.handleAttackCState.bind(this),
      validFrom: [
        FighterState.IDLE, FighterState.WALK_BACKWARDS, FighterState.WALK_FORWARD
      ]
    },
  }

  constructor({ sprite, velocity, position, name, direction, playerId }: PlayerOptions) {
    this.position = position
    this.direction = direction
    this.velocity.x = velocity * direction;
    this.sprite = sprite;
    this.name = name
    this.playerId = playerId

    this.changeState(FighterState.IDLE)
  }

  isAnimationCompleted = () => this.animations[this.currentState][this.animationFrame][1] === -2

  hasCollidedWithOpponent = () => rectsOverlap(
    this.position.x + this.pushBox.x, this.position.y + this.pushBox.y,
    this.pushBox.width, this.pushBox.height,
    this.opponent.position.x + this.opponent.pushBox.x,
    this.opponent.position.y + this.opponent.pushBox.y,
    this.opponent.pushBox.width, this.opponent.pushBox.height
  )

  IsWithinHitBox = () => rectsOverlap(
    this.position.x + this.pushBox.x, this.position.y + this.pushBox.y,
    this.pushBox.width + 50, this.pushBox.height,
    this.opponent.position.x + this.opponent.pushBox.x,
    this.opponent.position.y + this.opponent.pushBox.y,
    this.opponent.pushBox.width + 50, this.opponent.pushBox.height
  )

  resetVelocities() {
    this.velocity.x = 0
    this.velocity.y = 0
  }

  getDirection() {
    if (this.position.x + this.pushBox.x + this.pushBox.width <= this.opponent.position.x + this.opponent.pushBox.x + this.opponent.pushBox.width) {
      return FighterDirection.RIGHT
    } else if (this.position.x + this.pushBox.x >= this.opponent.position.x + this.opponent.pushBox.x + this.opponent.pushBox.width) {
      return FighterDirection.LEFT
    }
    return FighterDirection.LEFT
  }

  getPushBox(frameKey: any) {
    const [, [x, y, width, height] = [0, 0, 0, 0]] = this.frames.get(frameKey)
    return { x, y, width, height }
  }

  changeState(newState: any) {
    console.log("CHANGE", this.currentState, newState)
    if (newState === this.currentState || !this.states[newState].validFrom.includes(this.currentState)) {
      return
    }

    this.currentState = newState
    this.animationFrame = 0


    this.states[this.currentState].init()
  }

  handleIdleInit() {
    this.resetVelocities()
  }

  handleAttackAInit() {
  }

  handleAttackBInit() {
  }

  handleAttackCInit() {
  }

  handleJumpInit() {
    this.velocity.y = this.initialVelocity.jump
    this.handleMoveInit()
  }

  handleMoveInit() {
    this.velocity.x = this.initialVelocity.x[this.currentState] ?? 0
  }

  handleIdleState() {
    if (isUp(this.playerId)) this.changeState(FighterState.JUMP_START)
    else if (isBackward(this.playerId, this.direction)) this.changeState(FighterState.WALK_BACKWARDS)
    else if (isForward(this.playerId, this.direction)) this.changeState(FighterState.WALK_FORWARD)
    else if (isAttackA(this.playerId)) {
      this.switchAttackStateA()
    }
    else if (isAttackB(this.playerId)) {
      this.switchAttackStateB()
    }
    else if (isAttackC(this.playerId)) {
      this.switchAttackStateC()
    }
  }

  switchAttackStateA(){
    if (this.energy >= 20) {
      this.changeState(FighterState.IDLE)
      this.changeState(FighterState.ATTACK_A)
    }
  }

  switchAttackStateB(){
    if (this.energy >= 50) {
      this.changeState(FighterState.IDLE)
      this.changeState(FighterState.ATTACK_B)
    }
  }

  switchAttackStateC(){
    if (this.energy >= 20) {
      this.changeState(FighterState.IDLE)
      this.changeState(FighterState.ATTACK_C)
    }
  }

  handleWalkFowardState() {
    if (!isForward(this.playerId, this.direction)) this.changeState(FighterState.IDLE)
    else if (isUp(this.playerId)) this.changeState(FighterState.JUMP_START)
    else if (isAttackA(this.playerId)) {
      this.switchAttackStateA()
    }
    else if (isAttackB(this.playerId)) {
      this.switchAttackStateB()
    }
    else if (isAttackC(this.playerId)) {
      this.switchAttackStateC()
    }
  }

  handleWalkBackwardState() {
    if (!isBackward(this.playerId, this.direction)) this.changeState(FighterState.IDLE)
    else if (isUp(this.playerId)) this.changeState(FighterState.JUMP_START)
    else if (isAttackA(this.playerId)) {
      this.switchAttackStateA()
    }
    else if (isAttackB(this.playerId)) {
      this.switchAttackStateB()
    }
    else if (isAttackC(this.playerId)) {
      this.switchAttackStateC()
    }
  }

  handleAttackAState() {
    if (!this.isAttacking) {

      this.isAttacking = true

      if (this.energy >= 20 && this.IsWithinHitBox() && !this.opponent.isBlocking)
        this.opponent.hp -= 10

      this.energy -= 20
    }

    if (this.isAnimationCompleted()) {
      this.changeState(FighterState.IDLE)
      this.isAttacking = false
    }
  }

  handleAttackBState() {
    if (!this.isAttacking) {

      this.isAttacking = true

      if (this.energy >= 50 && this.IsWithinHitBox() && !this.opponent.isBlocking)
        this.opponent.hp -= 25

      this.energy -= 50
    }

    if (this.isAnimationCompleted()) {
      this.changeState(FighterState.IDLE)
      this.isAttacking = false
    }
  }

  handleAttackCState() {
    if (!this.isBlocking){
      this.isBlocking = true
      this.energy -= 20
    }
    if (this.isAnimationCompleted()) {
      this.changeState(FighterState.IDLE)
      this.isBlocking = false
    }
  }

  handleJumpState(frameTime: { previous: number, secondsPassed: number }) {
    this.velocity.y += this.gravity * frameTime.secondsPassed

    if (this.position.y > STAGE_FLOOR) {
      this.velocity.y = 0
      this.position.y = STAGE_FLOOR
      this.changeState(FighterState.JUMP_LAND)
    }
  }

  handleJumpStartState() {
    if (!this.isAnimationCompleted()) {
      if (isBackward(this.playerId, this.direction)) {
        this.changeState(FighterState.JUMP_BACKWARD)
      } else if (isForward(this.playerId, this.direction)) {
        this.changeState(FighterState.JUMP_FORWARD)
      } else {
        this.changeState(FighterState.JUMP_UP)
      }
    }
  }

  handleJumpStartInit() {
    this.resetVelocities()
  }

  handleJumpLandInit() {
    this.resetVelocities()
  }

  handleJumpLandState() {
    if (this.animationFrame < 1) return

    if (!isIdle(this.playerId)) {
      this.handleIdleState()
    } else if (!this.isAnimationCompleted()) {
      return
    }

    this.changeState(FighterState.IDLE)
  }

  updateStageConstraints(frameTime: { previous: number, secondsPassed: number }, context: any) {
    if (this.position.x > context.canvas.width - this.pushBox.width) {
      this.position.x = context.canvas.width - this.pushBox.width
    }

    if (this.position.x < this.pushBox.width) {
      this.position.x = this.pushBox.width
    }

    if (this.hasCollidedWithOpponent()) {
      if (this.position.x <= this.opponent.position.x) {
        this.position.x = Math.max(
          (this.opponent.position.x + this.opponent.pushBox.x) - (this.pushBox.x + this.pushBox.width),
          this.pushBox.width
        )

        if ([
          FighterState.IDLE,
          FighterState.JUMP_UP,
          FighterState.JUMP_FORWARD,
          FighterState.JUMP_BACKWARD
        ].includes(this.opponent.currentState)) {
          this.opponent.position.x += 150 * frameTime.secondsPassed
        }
      }

      if (this.position.x >= this.opponent.position.x) {
        this.position.x = Math.min(
          (this.opponent.position.x + this.opponent.pushBox.x + this.opponent.pushBox.width)
          + (this.pushBox.width + this.pushBox.x),
          context.canvas.width - this.pushBox.width
        )

        if ([
          FighterState.IDLE,
          FighterState.JUMP_UP,
          FighterState.JUMP_FORWARD,
          FighterState.JUMP_BACKWARD
        ].includes(this.opponent.currentState)) {
          this.opponent.position.x -= 150 * frameTime.secondsPassed
        }
      }
    }
  }

  updateAnimation(frameTime: { previous: number, secondsPassed: number }) {
    const animation = this.animations[this.currentState]
    const [frameKey, frameDelay] = animation[this.animationFrame]

    if (frameTime.previous > this.animationTimer + frameDelay) {
      this.animationTimer = frameTime.previous

      if (frameDelay > 0) {
        this.animationFrame++
        this.pushBox = this.getPushBox(frameKey)
      }

      if (this.animationFrame > animation.length - 1) {
        this.animationFrame = 0
      }
    }
  }

  update(frameTime: { previous: number, secondsPassed: number }, context: CanvasRenderingContext2D) {
    this.position.x += (this.velocity.x * this.direction) * frameTime.secondsPassed;
    this.position.y += this.velocity.y * frameTime.secondsPassed;

    if ([FighterState.IDLE, FighterState.WALK_FORWARD, FighterState.WALK_BACKWARDS]
      .includes(this.currentState)) {
      this.direction = this.getDirection()
    }

    this.energy += frameTime.secondsPassed * 15
    if (this.energy >= 100) {
      this.energy = 100
    }

    this.states[this.currentState].update(frameTime)
    this.updateAnimation(frameTime)
    this.updateStageConstraints(frameTime, context)
  }

  drawDebug(context: CanvasRenderingContext2D) {
    const [frameKey] = this.animations[this.currentState][this.animationFrame]
    const pushBox = this.getPushBox(frameKey)

    context.lineWidth = 1

    //  push box
    context.beginPath()
    context.strokeStyle = '#55FF55'
    context.fillStyle = '#55FF5555'
    context.fillRect(
      Math.floor(this.position.x + pushBox.x) + 0.5,
      Math.floor(this.position.y + pushBox.y) + 0.5,
      pushBox.width,
      pushBox.height
    )
    context.rect(
      Math.floor(this.position.x + pushBox.x) + 0.5,
      Math.floor(this.position.y + pushBox.y) + 0.5,
      pushBox.width,
      pushBox.height
    )
    context.stroke()



    //  origin
    context.beginPath()
    context.strokeStyle = 'red'
    context.moveTo(Math.floor(this.position.x) - 40, Math.floor(this.position.y) - 0.5)
    context.lineTo(Math.floor(this.position.x) + 40, Math.floor(this.position.y) - 0.5)
    context.moveTo(Math.floor(this.position.x) + 0.5, Math.floor(this.position.y - 40))
    context.lineTo(Math.floor(this.position.x) + 0.5, Math.floor(this.position.y + 40))
    context.stroke()
  }

  draw(context: CanvasRenderingContext2D | null) {
    const [frameKey] = this.animations[this.currentState][this.animationFrame]
    let originX = 260
    let originY = 520
    const [[x, y, w, h]] = this.frames.get(frameKey)
    context?.scale(this.direction, 1)
    context?.drawImage(
      this.sprite,
      x, y,
      w, h,
      Math.floor(this.position.x * this.direction) - originX, Math.floor(this.position.y) - originY,
      w, h);
    context?.setTransform(1, 0, 0, 1, 0, 0)
  }
}

