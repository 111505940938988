import React, { useEffect } from 'react';
import { GameBase } from './Game.base';


const Game: React.FC = () => {
    useEffect(() => {
        new GameBase().start()
        return () => {
            console.log('Component will unmount.');
        };
    }, []);


    return (
        <div className='h-full flex'>
            <canvas width={2000} height={1250} className='bg-yellow-700 w-full object-contain rendering-crisp-edges rendering-pixelated'
                style={{
                    height: "100vh"
                }}>
                <img src='./game/sprites/player1.png' className='hidden' alt='player1' />
                <img src='./game/sprites/player2.png' className='hidden' alt='player2' />
                <img src='./game/sprites/Background.png' className='hidden' alt='background' />
                <img src='./game/sprites/ui.png' className='hidden' alt='ui' />
            </canvas>
        </div>
    );
};

export default Game;