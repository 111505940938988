import { FighterDirection } from './Entities/Fighters/Game.Fighter';
import { Player1 } from './Entities/Fighters/Game.player1';
import { Player2 } from './Entities/Fighters/Game.player2';
import { Stage } from './Entities/Game.stage';
import { regiterKeyboardEvents } from './InputHandler';

export class GameBase {
    context = this.getContext() 
    fighters: any
    entities: any
    frameTime: any

    constructor() {
        this.fighters = [
            new Player1(FighterDirection.RIGHT, 0),
            new Player2(FighterDirection.LEFT, 1)
        ]

        this.fighters[0].opponent = this.fighters[1]
        this.fighters[1].opponent = this.fighters[0]

        this.entities = [
            new Stage(this.fighters),
            ...this.fighters
        ]

        this.frameTime = {
            previous: 0,
            secondsPassed: 0
        }
    }

    getContext() {
        const canvasEl = document.querySelector('canvas')
        const context = canvasEl?.getContext('2d') as CanvasRenderingContext2D
        context.imageSmoothingEnabled = false

        return context
    }

    update() {
        for (const entity of this.entities) {
            entity.update(this.frameTime, this.context)
        }

    }

    draw() {
        for (const entity of this.entities) {
            entity.draw(this.context)
        }
    }

    // debug(){
    //     for (const entity of this.entities){
    //         entity.drawDebug(this.context)
    //     }
    // }

    frame(time: any) {
        window.requestAnimationFrame(this.frame.bind(this))

        this.frameTime.secondsPassed = (time - this.frameTime.previous) / 1000
        this.frameTime.previous = time

        this.update()
        this.draw()
        // this.debug()
    }

    start() {   
        regiterKeyboardEvents()
        window.requestAnimationFrame(this.frame.bind(this))
    }
}