import React, { useEffect } from 'react';
import './App.css';
import Game from './components/Game/Game';

function App() {
  useEffect(() => {
    try {
      fetch('https://api.bigdatacloud.net/data/client-ip').then(response => {
        console.log(response)
        response.json().then(data => {
          console.log('Welcome User: ', data);
        })

      })
    } catch (error) {
    }
  //   const handleScroll = () => {
  //     let sky = document.getElementById("sky")
  //     let logo = document.getElementById("logo")

  //     let value = window.scrollY
  //     sky!.style.top = value * -0.5 + 'px';
  //     logo!.style.top = value * -0.5 + 'px';
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  }, []);

  return (
    <>
      {/* <header className='absolute top-0 left-0 w-full py-8 px-28 flex justify-start items-center z-50'>
        <h2 className='text-[#359381] pointer-events-none text-4xl'>Logo</h2>
        <nav>
          <a className='active' href="#">Home</a>
          <a href="#">Home</a>
          <a href="#">Home</a>
          <a href="#">Home</a>
        </nav>
      </header>

      <div className='parallax'>
        <img id='sky' src='images/Sky1.gif' />
        <img id='logo' src='images/Logo1.gif' />
        <img id='trees' src='images/Trees1.gif' />
      </div> */}

      <Game/>


    </>
  );
}

export default App;
